import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormArray,FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ROUTES } from '../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

    hidePass = true;
    hidePass1 = true;
    hidePass2 = true;
    public changePassForm: FormGroup;
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    success="";
    error="";
    token:any='';
    user_id:any ='';
    constructor(location: Location,  private element: ElementRef, private router: Router,private auth: AuthService,private fb: FormBuilder) {
      this.location = location;
          this.sidebarVisible = false;
          this.changePassForm = this.fb.group({
            old_password: [{ value: '', disabled: false }, [Validators.required]],
            new_password: [{ value: '', disabled: false }, [Validators.required]],
            confirm_pass: [{ value: '', disabled: false }, [Validators.required]]
         });
    }

    ngOnInit(){
        this.token=localStorage.getItem('belloauth_admintoken');
    this.user_id=localStorage.getItem('belloadminid');
      this.listTitles = ROUTES.filter(listTitle => listTitle);
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
      this.router.events.subscribe((event) => {
        this.sidebarClose();
         var $layer: any = document.getElementsByClassName('close-layer')[0];
         if ($layer) {
           $layer.remove();
           this.mobile_menu_visible = 0;
         }
     });
    }
    
    get f(){
        return this.changePassForm.controls;            
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass('nav-open');
            body.classList.remove('nav-open');
            if ($layer) {
                $layer.remove();
            }
            setTimeout(function() {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function() {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            }else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function() {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function() { //asign a function
              body.classList.remove('nav-open');
              this.mobile_menu_visible = 0;
              $layer.classList.remove('visible');
              setTimeout(function() {
                  $layer.remove();
                  $toggle.classList.remove('toggled');
              }, 400);
            }.bind(this);

            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;

        }
    };

    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }

      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }
      return 'Dashboard';
    }

    logout(){
      const data={
        user_id:localStorage.getItem('belloadminid')
      }
      this.auth.commonpostService('auth/logout',data).subscribe((res : any) => {
      localStorage.setItem('belloauth_admintoken', '');
      localStorage.setItem('belloadminid','');
      this.router.navigate(['/']);  
      });   
    }

    submitChangepasswordForm(){
         let data = this.changePassForm.value;
         console.log(data);
        if (!this.changePassForm.invalid) {

            data.user_id=this.user_id;

            console.log(data);
            
            
            this.auth.commonpostService("user/change_password",data).subscribe((res : any) => {
            if(res.status == '1'){  
                $("html,body").animate({
                scrollTop: 0
                }, "slow");
                this.error='';
                this.success='Password has been changed successfully.';
                this.changePassForm.reset();
                (<any>$('#chage-password')).modal('hide');			 
                this.ngOnInit();
                setTimeout(() => {
                  this.success="";
                }, 4000);
                // this.router.navigate(['/patient-management']);
            }else{
                this.success="";
                  this.error=res.message;
                  console.log(res);
               }
            }); 
          }else{
            this.changePassForm.valueChanges;
          }
   } 
}
