import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { SetPasswordComponent } from './auth/set-password/set-password.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';


const routes: Routes =[
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
    { path: 'login', component: LoginComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'set-password/:id', component: SetPasswordComponent },
   {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }

export const ENDPOINTS = {
  BLOG_VIEW_EMAIL:'listing/blog_view_email',
  STAFF_LIST:'listing/staff_list',
  STAFF_PRAC_LIST:'listing/staff_prac_list?staff_id={SID}',
  HANDLE_SUBSCRIPTION:'admin/change_company_subscription_status',
  CREATE_STAFF:'admin/create_staff',
  STAFF_BY_ID:'staff_details?staff_id={SID}',
  UPDATE_STAFF_STATUS:'change_status_staff?staff_id={SID}&status={STATUS}',
  SCHEDULE_STAFF:'admin/schedule_publish',
  PUSH_NOTIFICATION:'listing/push_notification_listing',
  SUBSCRIPTION_LISTING:'listing/subscription_listing',
  SUBSCRIPTION_PLAN_LISTING:'listing/subscription_plan_listing',
  CHANGE_COMPANY_SUBSCRIPTION:'admin/change_company_subscription',
}
