import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-reason',
  templateUrl: './add-reason.component.html',
  styleUrls: ['./add-reason.component.css']
})
export class AddReasonComponent implements OnInit {

  public languageForm: FormGroup;
  token:any='';
  user_id:any =''; 
  active:any=0;
  inactive:any=0;  
  success="";
  error="";
  details:any="";
  sid="";
  submitted=false;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,private fb: FormBuilder){ 
      this.languageForm = this.fb.group({
        name: [{ value: '', disabled: false }, [Validators.required]]                
        });
      

    }

get f(){
  return this.languageForm.controls;            
}

ngOnInit(): void {
   this.token=localStorage.getItem('belloauth_admintoken');
     this.user_id=localStorage.getItem('belloadminid');
   if(this.token == null || this.token ==""){
      this.router.navigate(['/']);
    }
if(this.route.snapshot.paramMap.get('id') !=undefined && this.route.snapshot.paramMap.get('id') !=""){

this.sid=this.route.snapshot.paramMap.get('id');

  this.auth.commongetService("listing/contact_reasons?id="+this.route.snapshot.paramMap.get('id')).subscribe((res : any) => {
  if(res.status == '1'){         
      this.details=res.data[0];
      console.log(this.details);
      this.languageForm = this.fb.group({
          name: [{value:this.details.name, disabled: false },[Validators.required]]
        });
     }
  });
  }
}


submitForm(){ 
      this.submitted=true;
      let data=this.languageForm.value;
      console.log(data);
      if (!this.languageForm.invalid) {

        data.sid=this.sid;
        data.user_id=this.user_id;
        console.log(data);
        
        this.auth.commonpostService("admin/add_reason",data).subscribe((res : any) => {
        if(res.status == '1'){  
            $("html,body").animate({
            scrollTop: 0
            }, "slow");
            if(this.sid==""){
              this.languageForm.reset();
              this.submitted=false;
               this.success='Reason has been added successfully.';
            }else{
              this.success='Reason has been updated successfully.';
            }
            setTimeout(() => {
              this.router.navigate(['/contact-reasons']);
            }, 4000);
            // this.router.navigate(['/patient-management']);
        }else{
              this.error=res.message;
              console.log(res);
           }
        }); 
      }
  }


}
