import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, throwError, BehaviorSubject  } from 'rxjs';
import { catchError, map, tap, filter, take, switchMap } from 'rxjs/operators';
import { environment } from '../../../src/environments/environment';
import { AuthService } from "../services/auth.service";


@Injectable({
  providedIn: 'root'
})
export class IntercepterService {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private router: Router, private authenticationService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = req.url.split('/');
    let device_id :any=false;
    
    let accessToken :any= '';
    let headers = new HttpHeaders({"Content-Type": `application/json; charset=UTF-8`,"apikeys":environment.API_KEY});

    if (localStorage.getItem('belloauth_admintoken')) {
      accessToken = localStorage.getItem('belloauth_admintoken');
      headers = new HttpHeaders({
        "Authorization": `${accessToken}`,
        "Content-Type": `application/json; charset=UTF-8`,
        "apikeys":environment.API_KEY
      });
    }
    
    const reqClone = req.clone({ headers });
    return next.handle(reqClone).pipe(
      tap((response: any) => {
        const Res = response;
        if (Res.body) {
          if (Res.body.status == '4') {
            localStorage.clear();
            localStorage.setItem('session', Res.body.message);
            this.router.navigate(['/']);
          }
        }
      }), map((response) => response),
    );
  }

}
