import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';



import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatRippleModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatIconModule} from '@angular/material/icon';

import { IntercepterService } from "./intercepter/intercepter.service";


import { NavbarComponent } from './components/navbar/navbar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PractitionerManagementComponent } from './user-management/practitioner-management/practitioner-management.component';
import { PractitionerManagementDetailComponent } from './user-management/practitioner-management-detail/practitioner-management-detail.component';
import { PractitionerManagementEditComponent } from './user-management/practitioner-management-edit/practitioner-management-edit.component';
import { PatientManagementComponent } from './user-management/patient-management/patient-management.component';
import { PatientManagementDetailComponent } from './user-management/patient-management-detail/patient-management-detail.component';
import { PatientManagementDetailEditComponent } from './user-management/patient-management-detail-edit/patient-management-detail-edit.component';
import { SpecialityManagementListingComponent } from './speciality-management/speciality-management-listing/speciality-management-listing.component';
import { SpecialityManagementDetailComponent } from './speciality-management/speciality-management-detail/speciality-management-detail.component';
import { SpecialityManagementEditComponent } from './speciality-management/speciality-management-edit/speciality-management-edit.component';

import { AppointmentsListingComponent } from './appointments/appointments-listing/appointments-listing.component';
import { SubAdminListingComponent } from './sub-admin-management/sub-admin-listing/sub-admin-listing.component';
import { SubAdminEditComponent } from './sub-admin-management/sub-admin-edit/sub-admin-edit.component';
import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { SendPushNotificationComponent } from './send-notification/send-push-notification/send-push-notification.component';
import { AboutUsComponent } from './cms/about-us/about-us.component';
import { EditorModule } from "@tinymce/tinymce-angular";
import { PrivacyPolicyComponent } from './cms/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './cms/terms-and-conditions/terms-and-conditions.component';
import { SocialMediaComponent } from './cms/social-media/social-media.component';
import { ContactUsComponent } from './cms/contact-us/contact-us.component';
import { ManageHighlightsListingComponent } from './manage-highlights/manage-highlights-listing/manage-highlights-listing.component';
import { BlogListingComponent } from './blog-management/blog-listing/blog-listing.component';
import { BlogAddComponent } from './blog-management/blog-add/blog-add.component';
import { BlogEditComponent } from './blog-management/blog-edit/blog-edit.component';
import { ViewCommentsComponent } from './blog-management/view-comments/view-comments.component';
import { FaqListingComponent } from './faq/faq-listing/faq-listing.component';
import { FaqAddComponent } from './faq/faq-add/faq-add.component';
import { FaqEditComponent } from './faq/faq-edit/faq-edit.component';
import { SpecialityAddComponent } from './speciality-management/speciality-add/speciality-add.component';
import { SubAdminAddComponent } from './sub-admin-management/sub-admin-add/sub-admin-add.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { BlogDetailComponent } from './blog-management/blog-detail/blog-detail.component';
import { TransactionInformationComponent } from './transaction-information/transaction-information.component';
import { SubAdminDetailComponent } from './sub-admin-management/sub-admin-detail/sub-admin-detail.component';
import { ManageHighlightsEditComponent } from './manage-highlights/manage-highlights-edit/manage-highlights-edit.component';
import { PayoutReportComponent } from './payout-report/payout-report.component';
import { ViewTransactionComponent } from './view-transaction/view-transaction.component';
import { GenieYourServiceComponent } from './user-management/genie-your-service/genie-your-service.component';
import { BlogCategoryComponent } from './blog-category/blog-category/blog-category.component';
import { BlogCategoryAddComponent } from './blog-category/blog-category-add/blog-category-add.component';
import { SetPasswordComponent } from './auth/set-password/set-password.component';
import { CompanyListingComponent } from './company-management/company-listing/company-listing.component';
import { StaffListingComponent } from './user-management/staff-management/staff-listing/staff-listing.component';
import { AddCompanyComponent } from './company-management/add-company/add-company.component';
import { EditCompanyComponent } from './company-management/edit-company/edit-company.component';
import { ImportCompanyComponent } from './company-management/import-company/import-company.component';
import { CompanyDetailComponent } from './company-management/company-detail/company-detail.component';
import { AddEmployeeComponent } from './company-management/add-employee/add-employee.component';
import { LanguageListingComponent } from './language/language-listing/language-listing.component';
import { AddLanguageComponent } from './language/add-language/add-language.component';
import { AddReasonComponent } from './contact-reason/add-reason/add-reason.component';
import { ReasonListingComponent } from './contact-reason/reason-listing/reason-listing.component';
import { NewsletterListingComponent } from './newsletter-listing/newsletter-listing.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgSelectModule } from '@ng-select/ng-select';


// import { GoogleChartsModule } from 'angular-google-charts';
 

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatRadioModule,
    EditorModule,
    MatIconModule,
    // NgSelectModule,
    // GoogleChartsModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    }),
    NgbModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SetPasswordComponent,
    LanguageListingComponent,
    AddLanguageComponent,
    AddReasonComponent,
    ReasonListingComponent,
    NewsletterListingComponent,
  ],
  providers: [
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: IntercepterService,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
