import { Component, OnInit , ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { PagerService } from '../services/pager.service';

@Component({
  selector: 'app-newsletter-listing',
  templateUrl: './newsletter-listing.component.html',
  styleUrls: ['./newsletter-listing.component.css']
})
export class NewsletterListingComponent implements OnInit {

  public searchForm: FormGroup;
  token:any='';
  user_id:any ='';
  ptotal:any = 0;
  allItems: any=[];
  pager: any = {};
  pagedItems: any=[];
  base_path   =environment.baseAppUrl;
  limit:any =10;
  total:any=0;
  active:any=0;
  inactive:any=0;
  order_by="";
  sid="";
  success="";

  constructor(private router: Router,private route: ActivatedRoute,private auth: AuthService, private pagerService: PagerService,private ref: ChangeDetectorRef,private fb: FormBuilder,) {

    this.searchForm = this.fb.group({
      status: 'all',
      name: ''
      });
  
     }

  changeorderBy(orderby){
    this.order_by=orderby;
    this.ngOnInit();
    //this.all_practitioner(page-1,data);
    }
  
ngOnInit(): void {
     this.token=localStorage.getItem('belloauth_admintoken');
    this.user_id=localStorage.getItem('belloadminid');
    let data="";
  
    if(this.searchForm.value.name !=undefined && this.searchForm.value.name !=''){
      data=data+"&search="+this.searchForm.value.name.trim();
   }
  
  
      if(this.token == null || this.token ==""){
            this.router.navigate(['/']);
      }
      this.auth.commongetService("listing/newsletter_listing?type=total"+data).subscribe((res : any) => {
        console.log(res);
        if(res.status == '1'){          
          this.total 	=res.data.data.total;   
          this.ptotal =res.data.data.total;   
          this.setPagepatient(1);
        }
      });
     
    }
    
    searchList(){
      this.ngOnInit();
    }
  
      setPagepatient(page: number) {
        if (this.pager) {
          if (page < 1 ) { //|| page > this.pager.totalPages
            return;
          }
      }
      
      let data="";
  
      if(this.searchForm.value.name !=undefined && this.searchForm.value.name !=''){
         data=data+"&search="+this.searchForm.value.name.trim();
      }
     
       
  
        this.pager = this.pagerService.getPager(this.ptotal, page, true, this.limit);
        this.listing(page-1,data);
    }
  
    listing(off:any,data){
      if(this.order_by !=""){
        data=data+"&orderby="+this.order_by;
      }
        off=off*this.limit;
         this.auth.commongetService("listing/newsletter_listing?limit="+this.limit+"&offset="+off+data).subscribe((res : any) => {
          if(res.status == '1'){
            this.allItems = res.data.data;
            this.ref.detectChanges();
            //this.setPage(1);
          }
        });
  
    }
  
  deleteId(sid){
      this.sid=sid;		
   }
  
   deleteFormSubmit(){
    let data={sid:this.sid
         };
    this.auth.commonpostService("admin/delete_newsletter",data).subscribe((res : any) => {
      console.log(res);
      if(res.status == '1'){
        this.success="Newsletter has been deleted successfully.";        			 
        this.ngOnInit();
        setTimeout(() => {
          this.success=""; 
        }, 4000);
      }
    });
  }
    
    class_check(st:any){
      let cls = 'text-warning';
      if(st=='active'){
        cls ='text-success';
      }else if(st=='inactive'){
        cls ='text-danger';
      }
      return cls;
    }
  

}
