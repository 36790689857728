import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  httpOptions: any;
  accessToken :any= '';
  
  constructor(private http: HttpClient) {
    if (localStorage.getItem('belloauth_admintoken')) {
      this.accessToken = localStorage.getItem('belloauth_admintoken');
    }
    this.httpOptions = new HttpHeaders({
      "Authorization": `${this.accessToken}`,
      "Content-Type": `application/json; charset=UTF-8`
    });
  }
  
  signinService(data:any) { return this.http.post(environment.baseAppUrl + '/api/admin/auth/login',data); }
  commongetService(data:any) { return this.http.get(environment.baseAppUrl + '/api/admin/'+data); }
  commongetGlobalService(data:any) { return this.http.get(environment.baseAppUrl + '/api/'+data); }
  commonpostService(url:any,data:any) { return this.http.post(environment.baseAppUrl + '/api/'+url,data); }

}
