import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hidePass = true;
  token:any='';
  signinForm: FormGroup;
  isSubmitted= false;
  error_message='';

  constructor(private router: Router,private formBuilder: FormBuilder,private route: ActivatedRoute,private auth: AuthService) { 
  	this.signinForm = this.formBuilder.group({
           email_id: ['', Validators.required],
           password: ['', Validators.required]
    });
  }

  ngOnInit(): void {

  	this.token=localStorage.getItem('belloadminid');
    if(this.token != null && this.token !=""){
      this.router.navigate(['/dashboard']);
    }
  }

  get signinFrm() { return this.signinForm.controls; }
    
  signin(){
  	this.isSubmitted= true;
  	if (this.signinForm.invalid) {
    	return;
	}
	const data={
		email_id : this.signinForm.get('email_id')?.value,
		password : this.signinForm.get('password')?.value
	}
	this.auth.signinService(data).subscribe((res : any) => {
        if(res.status == '1'){
       //   console.log('access check',res.data.access);
        	this.isSubmitted = false;
          localStorage.setItem('belloadminid', res.data.id);
          localStorage.setItem('access', res.data.access);
        	localStorage.setItem('belloauth_admintoken', res.data.token);
        	
          this.router.navigate(['/dashboard']);
        }else{
        	this.error_message=res.message;
        	setTimeout(() => {
	          this.error_message = '';
	        }, 6000);
        }
    });
  }

}
