// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  baseAppUrl: 'https://api.bello.haus',
  // baseAppUrl: 'https://api.staging.bello.haus',
  //  baseAppUrl: 'https://indeedtraining.in:6179',
  API_KEY: 'bello@user#123',
  production: false,
  TINY_MCE_EDITOR_KEY:'v0yrl7bj1vh32sc9fzui4k6uk2n7s4fmjhog8g5m28k5iehl',
};
