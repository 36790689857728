import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {
  hidePass = true;
  hidePass1 = true;

  public setPasswordForm: FormGroup;
  token:any='';
  user_id:any =''; 
  active:any=0;
  inactive:any=0;  
  success="";
  error="";
  details:any="";
  sid="";
  confirmpasserr="";

  constructor(private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,private fb: FormBuilder){ 
      this.setPasswordForm = this.fb.group({
        password: [{ value: '', disabled: false }, [Validators.required]],
        confirmpassword: [{ value: '', disabled: false }, [Validators.required]]                 
      });
     
    }

get f(){
  return this.setPasswordForm.controls;            
}

ngOnInit(): void {
  
  if(this.route.snapshot.paramMap.get('id') !=undefined && this.route.snapshot.paramMap.get('id') !=""){
    this.sid=this.route.snapshot.paramMap.get('id');
    }
}


submitForm(){
      let data=this.setPasswordForm.value;
      console.log(data);
      if (!this.setPasswordForm.invalid) {
        if(data.password !== data.confirmpassword){
          this.confirmpasserr="Password must match with confirm password.";
          return false;
        }
        this.confirmpasserr="";
        data.sid=this.sid;
       
        
        this.auth.commonpostService("admin/auth/set_subadmin_password",data).subscribe((res : any) => {
        if(res.status == '1'){  
            $("html,body").animate({
            scrollTop: 0
            }, "slow");
            this.setPasswordForm.reset();
           this.success='Password has been updated successfully.';
            
            setTimeout(() => {
              this.router.navigate(['/login']);
            }, 4000);
            
        }else{
              this.error=res.message;
              console.log(res);
           }
        }); 
      }
  }

}
